import React, { PureComponent } from 'react';
import { Form, Field } from 'react-final-form'
import styled from 'styled-components';
import isEmail from 'validator/lib/isEmail';
import axios from 'axios';
import { FormApi } from 'final-form';

const Wrapper = styled.div`
  margin-top: 5em;
  margin-bottom: 5em;
  
  form {
    margin-top: 3em;
    width: 100%;
    max-width: 55em;
    input, textarea {
      font-size: 1.8em;
      line-height: 1.8em;
      outline: none;
      width: 100%;
      border: none;
      border-bottom: 2px solid #de5c12;
      padding-bottom: 10px;
      margin-top: 20px;
      
      &::placeholder {
        font-weight: 300;
        font-size: ${1.8/1.8}em;
        line-height: ${1.8/1.8}em;
      }
    }
    
    button {
      margin: 2em 0;
      &[type="submit"] {
        border: none;
        outline: none;
        font-weight: 600;
        font-size: 2em;
        border-bottom: 2px solid #de5c12;
        padding: ${1/2}em ${2/2}em ${0.8/2}em 0;
        background-color: transparent;
        color: #de5c12;
        
        &:disabled {
          opacity: 0.2;
        }
      }
    }
  }
`;

type State = {
  formMessage: string;
  type: 'ERROR' | 'SUCCEED' | string;
}

class ContactForm extends PureComponent<{}, State> {
  state = {
    formMessage: '',
    type: 'SUCCEED'
  };

  render() {
    const { formMessage, type } = this.state;

    const messageColor: string = type === 'SUCCEED' ? 'green' : 'red';

    return (
      <Wrapper>
        <h3>Ota yhteyttä lomakkeella, niin palaamme asiaan mahdollisimman pian!</h3>
        <Form
          onSubmit={this._onSubmit}
          validate={(values: any) => {
            const errors: any = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (!values.email || !isEmail(values.email)) {
              errors.email = "Required";
            }
            if (!values.message) {
              errors.message = "Required";
            }
            return errors;
          }}
          render={({ handleSubmit, submitting, pristine, valid }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <Field name="name" component="input" placeholder="Nimi *" />
              </div>

              <div>
                <Field name="email" component="input" placeholder="Sähköposti *" />
              </div>

              <div>
                <Field name="phone" component="input" placeholder="Puhelin" />
              </div>

              <div>
                <Field name="message" component="textarea" placeholder="Viesti *" />
              </div>

              {Boolean(formMessage) &&
                <div style={{marginTop: '1em'}}>
                  <p style={{color: messageColor}}>{formMessage}</p>
                </div>
              }

              <button type="submit" disabled={submitting || pristine || !valid}>
                Lähetä
              </button>
            </form>
          )}
        />
      </Wrapper>
    );
  }

  private _onSubmit = async (values: any, form: FormApi) => {
    this.setState({formMessage: ''});

    axios({
      method: 'post',
      // url: 'https://formspree.io/nt@ammattisaneeraus.fi',
      url: 'https://formspree.io/pastinen@ammattisaneeraus.fi',
      data: {
        nimi: values.name,
        sahkoposti: values.email,
        puhelin: values.phone,
        viesti: values.message
      }
    })
      .then(() => {
        form.reset();
        this.setState({type: 'SUCCEED', formMessage: 'Lomake on lähetetty onnistuneesti! Olemme sinuun yhteydessä mahdollisimman pian.'})
      })
      .catch(() => {
        this.setState({type: 'ERROR', formMessage: 'Lomakeen lähetys epäonnistui. Ota yhteyttä sähköpostilla tai yritä myöhemmin uudelleen.'})
      });
  }
}

export default ContactForm;