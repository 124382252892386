import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Company = styled.section`
  .block_intro {
    display: flex;
    
    .block-intro-image-wrapper {
      display: none;
    }
    
    ${breakpoint('lg')`
      .block_intro__text {
        padding-right: 3em;
      }
      
      .block-intro-image-wrapper {
        display: block;
        width: 57%;
     
        .block_intro__image {
          padding-top: 130%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    `}
  }

  .logo-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7em;
    padding: 0;
    
    li {
      display: block;
      margin-top: 3em;
      
      &:first-child {
        margin-top: 0;
      }
    }
    
    ${breakpoint('sm')`
      flex-direction: row;
      
      li {
        margin-left: 3em;
        margin-top: 0;
        
        &:first-child {
          margin-left: 0;
        }
      }
    `}
  }
`;
