import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Contact = styled.section`
  .contact-grid {
    display: flex;
    flex-direction: column;
    
    .contact-person {
      display: flex;
      flex-direction: row;
      margin-top: 4em;
      
      &:first-child {
        margin-top: 0;
      }
      
      figure {
        display: none;
      }
    }
    
    ${breakpoint('lg')`
      flex-direction: row;
      
      .contact-person {
        margin-right: 4em;
        margin-top: 0;
      }
    `}
    
    ${breakpoint('sm')`
      .contact-person {
        .contact-person-info {
          margin-left: 2em;
        }
        
        figure {
          display: block;
          width: 10em;
          height: 10em;
          background-color: red;
          border-radius: 5em;
          overflow: hidden;
        }
      }
    `}
  }
`;