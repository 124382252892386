import React from 'react'
import { withSiteData } from 'react-static';
import { Contact } from '../styles/contact-section';
import { Services, ServicesCarouselItem } from '../styles/services-section';
import { Clients } from '../styles/clients-section';
import { Company } from '../styles/section-company';
import ContactBlock from '@components/ContactBlock';
import Carousel from '@components/Carousel';

export default withSiteData(() => (
  <div>
    <Company id={'yritys'} className={'content-section'}>
      <h2>Yritys</h2>
      <div className={'block_intro'}>
        <div className={'block_intro__text'}>
          <div>
            <p>Olemme pääsääntöisesti pääkaupunkiseudulla toimiva rakennus- ja remontointipalvelua tarjoava yritys.</p>
            <p>Yrityksellemme tärkeimpiä kriteerejä ovat asiakkaan tyytyväisyys, ensiluokkainen työnjälki, sekä luvatuissa aikatauluissa pysyminen.</p>
            <p>Meidän kauttamme saat kaiken kattavat remonttipalvelut yhdellä sopimuksella.</p>
            <p>Laaja yhteistyöverkostomme mahdollistaa myös esimerkiksi putki- ja sähkötöiden järjestämisen työkohteeseen.</p>
          </div>
          <ul className={'logo-list'}>
            <li>
              <a href="https://www.tilaajavastuu.fi/fi/pikatarkistus/?yt=2928585-5" target="_blank">
                <img src={'/images/certificates/luotettavakumppani.png'} srcSet={`${'/images/certificates/luotettavakumppani.png'} 1x, ${'/images/certificates/luotettavakumppani@2x.png'} 2x`} />
              </a>
            </li>
            <li>
              <a href="https://www.sertifikaattihaku.fi/certificates/C-23559-23-17" target="_blank">
                <img src={'/images/certificates/ES_Rakentaja_markatilojen.png'} srcSet={`${'/images/certificates/ES_Rakentaja_markatilojen.png'} 1x, ${'/images/certificates/ES_Rakentaja_markatilojen@2x.png'} 2x`} />
              </a>
            </li>
          </ul>
        </div>
        <div className={'block-intro-image-wrapper'}>
          <figure className={'block_intro__image'} style={{backgroundImage:`url(/images/company/hard-working.jpg`}}/>
        </div>
      </div>
   </Company>

    <div id={'palvelut'}>
    <Services className={'content-section'}>
      <h2>Palvelut</h2>
      <p>Meiltä kaikki tarvittavat palvelut remonttiin saman katon alta.</p>
      <div className={'services-content'}>
        <div>
          <ul className={'list'}>
            <li><p>huoneistoremontit</p></li>
            <li><p>terassit, patiot, piha-aidat &amp; pienet piharakennukset</p></li>
            <li><p>kylpyhuoneremontit</p></li>
            <li><p>saunat</p></li>
            <li><p>keittiöremontit</p></li>
            <li><p>maalaustyöt</p></li>
            <li><p>lattioiden asennukset</p></li>
            <li><p>kalusteasennukset</p></li>
            <li><p>vedeneristykset</p></li>
            <li><p>mikrosementoinnit</p></li>
          </ul>
        </div>
        <div>
          <Carousel>
            <ServicesCarouselItem className={'carousel-item'} image={'/images/services/terassi.jpg'}>
              <span className={'label'}>terassit, patiot, piha-aidat & pienet piharakennukset</span>
            </ServicesCarouselItem>
            <ServicesCarouselItem className={'carousel-item'} image={'/images/services/terassi-2.jpg'}>
              <span className={'label'}>terassit, patiot, piha-aidat & pienet piharakennukset</span>
            </ServicesCarouselItem>
            <ServicesCarouselItem className={'carousel-item'} image={'/images/services/terassi-3.jpg'}>
              <span className={'label'}>terassit, patiot, piha-aidat & pienet piharakennukset</span>
            </ServicesCarouselItem>
            <ServicesCarouselItem className={'carousel-item'} image={'/images/services/sauna.jpg'}>
              <span className={'label'}>saunat</span>
            </ServicesCarouselItem>
            <ServicesCarouselItem className={'carousel-item'} image={'/images/services/keittio.jpg'}>
              <span className={'label'}>keittiöremontit</span>
            </ServicesCarouselItem>
            <ServicesCarouselItem className={'carousel-item'} image={'/images/services/kylpyhuone.jpg'}>
              <span className={'label'}>kylpyhuoneremontit</span>
            </ServicesCarouselItem>
            <ServicesCarouselItem className={'carousel-item'} image={'/images/services/mikrosementointi.jpg'}>
              <span className={'label'}>mikrosementoinnit</span>
            </ServicesCarouselItem>
            <ServicesCarouselItem className={'carousel-item'} image={'/images/services/vesieristys.jpg'}>
              <span className={'label'}>vedeneristykset</span>
            </ServicesCarouselItem>
          </Carousel>
        </div>
      </div>
    </Services>
    </div>

    <Clients id={'asiakkaamme'} className={'content-section'}>
      <h2>Asiakkaamme</h2>
      <ul className={'list'}>
        <li><p>Yksityiset henkilöt</p></li>
        <li><p>Rakennus- ja muut yritykset</p></li>
        <li><p>Taloyhtiöt</p></li>
        <li><p>Isännöintiyritykset</p></li>
      </ul>
      <p>Työmme on kotitalousvähennyskelpoista! Säästä rahaa teettäessäsi remonttia ja hyödynnä kotitalousvähennyksiä verotuksessa.</p>
      <p>Vuonna 2019 voit saada kotitalousvähennystä maksimissaan 2 400 euroa vuodessa. Vähennyksen omavastuu on 100 euroa henkilöltä vuodessa.</p>
      <p>Kun tilaat työsi meiltä, voit vähentää 50 % arvonlisäverollisesta työn osuudesta. Saat maksimivähennyksen, kun yritykselle maksamastasi laskusta työn osuus on 5 000 euroa (5 000 euroa x 50 %) – omavastuu 100 euroa = 2 400 euroa.</p>
    </Clients>

    <section id={'referenssit'} className={'content-section'}>
      <h2>Referenssit</h2>
      <p>Referenssikuvia valmistuneista remonteista voit katsella <a href="https://www.facebook.com/ammattisaneeraus/" target="_blank">Facebook sivuiltamme</a>.</p>
    </section>

    <Contact id={'yhteystiedot'} className={'content-section'}>
      <h2>Yhteystiedot</h2>
      <div className={'contact-grid'}>
        <div className={'contact-person'}>
          <figure>
            <img src={'/images/niko.jpg'} srcSet={`${'/images/niko.jpg'} 1x, ${'/images/niko@2x.jpg'} 2x`} />
          </figure>
          <div className={'contact-person-info'}>
            <p><strong>Niko Pastinen</strong>, Rakennusurakointi</p>
            <p>puhelin/whatsapp: <a href="tel:+358503072888">0503072888</a></p>
            <p><a href="mailto:pastinen@ammattisaneeraus.fi">pastinen@ammattisaneeraus.fi</a></p>
          </div>
        </div>

        <div className={'contact-person'}>
          <figure>
            <img src={'/images/tuomas.jpg'} srcSet={`${'/images/tuomas.jpg'} 1x, ${'/images/tuomas@2x.jpg'} 2x`} />
          </figure>
          <div className={'contact-person-info'}>
            <p><strong>Tuomas Mönkäre</strong>, Maalausurakointi</p>
            <p>puhelin/whatsapp: <a href="tel:+358503258636">0503258636</a></p>
            <p><a href="mailto:monkare@ammattisaneeraus.fi">monkare@ammattisaneeraus.fi</a></p>
          </div>
        </div>
      </div>

      <ContactBlock />
    </Contact>
  </div>
))
