import React, { Component, ReactNode } from 'react';
import Slider from 'react-slick';

type Props = {
  children: ReactNode;
}

class Carousel extends Component<Props> {
  render() {
    const { children } = this.props;

    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      pauseOnHover: true,
      pauseOnDotsHover: true
    };

    return (
      <Slider {...settings}>
        {children}
      </Slider>
    );
  }
}

export default Carousel;