import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Services = styled.section`
  .services-content {
    display: flex;
    flex-direction: column;
    
    > * {
      width: 100%;
      margin-top: 4em;
      &:first-child {
        margin-top: 0;
      }
    }
    
    ${breakpoint('lg')`
      flex-direction: row;
      justify-content: space-between;
      
      > * {
        width: 48%;
        margin-top: 0;
      }
    `}
    
    .carousel-item {
      position: relative;
      background-color: black;
      padding-top: 65.974%;
      
      > .label {
        position: absolute;
        max-width: 90%;
        bottom: 0;
        left: 0;
        background-color: white;
        padding: ${1/1.4}em;
        font-weight: 400;
        font-size: 1.4em;
        z-index: 2;
      }
    }
  }
`;

type ServicesCarouselItemProps = {
  image: string;
}

export const ServicesCarouselItem = styled.div<ServicesCarouselItemProps>`
  background: url(${props => props.image});
  background-size: cover;
`;
