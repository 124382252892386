import React, { Component } from 'react';
import ContactForm from '@components/ContactForm';

class ContactBlock extends Component {
  render() {
    return (
      <ContactForm />
    );
  }
}

export default ContactBlock;